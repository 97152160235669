import React from "react";
import ReactDOM from "react-dom/client";
import Providers from "./Providers";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>
);
