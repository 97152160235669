import { MaritalStatus, Person } from "../../../lib/types";
import MyDataUserDetails from "../../molecules/myData/MyDataUserDetails";
import MyDataContactDetails from "../../molecules/myData/MyDataContactDetails";
import MyDataPartnerDetails from "../../molecules/myData/MyDataPartnerDetails";

interface Props {
  user: Person;
}

const MyDataFirstTab: React.FC<Props> = ({ user }) => {
  const tailwindClasses = "col-span-12 lg:col-span-5 lg:odd:col-start-2";
  const hasPartner = user.maritalStatus !== null && user.maritalStatus !== MaritalStatus.CHILD;

  return (
    <>
      <div className={tailwindClasses}>
        <MyDataUserDetails user={user} />
      </div>
      <div className={tailwindClasses}>
        <MyDataContactDetails user={user} />
      </div>
      {hasPartner && (
        <div className={tailwindClasses}>
          <MyDataPartnerDetails user={user} />
        </div>
      )}
    </>
  );
};

export default MyDataFirstTab;
