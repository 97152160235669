/*
[SIT]
Person ID   BSN         PersonUUID                              Description
23156531    278118525   09c305ef-4c29-4cf9-9a3b-485473752b81    Non-Retired, Dutch
23156532    483075188   6caa85ce-a51a-479f-b8cb-177f376f3485    Non-Retired, English
23156533    185726288   5b12cc7d-c14f-40d9-95cf-6f59f1bfc32f    Non-Retired, Dutch
23156535    465058334   e5643af2-eb44-4926-a7e5-b7530d9d8c22    Non-Retired, Dutch
23156536    235848608   992dc3fe-7e2c-49b2-820e-ed942857281f    Non-Retired, Dutch
23156832    364080486   54e7cf47-0784-4cea-bcee-3d6bcf422707    Retired, Dutch
22834647    620175072   b781377d-4d79-4a70-b19f-b9baf1484c6f    Retired, Dutch
********    772416631   ************************************    unknown, Dutch, has partner
********    537385393   ************************************    retired, Dutch, has planner access
*/

const uuidName = "person_uuid";

export const getUuid = () => localStorage.getItem(uuidName);
export const setUuid = (uuid: string) => localStorage.setItem(uuidName, uuid);
