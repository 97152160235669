import { getAuthToken } from "../utils/token";
import { getUuid } from "../utils/uuid";

export enum APILanguage {
  EN = "en_US",
  NL = "nl_NL",
}

export enum PortalLanguage {
  EN = "en",
  NL = "nl",
}

const getHeaders = () => ({
  Authorization: "Bearer " + getAuthToken() || "",
  Accept: "application/json",
  "Content-Type": "application/json",
  "Api-Version": "OAuth",
});

export const initLanguage = async () => {
  try {
    const response = await fetch(window.env.VITE_API_BASE_URL + `persons/${getUuid()}`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const data = await response.json();
    if (data?.person?.language === APILanguage.EN) {
      return PortalLanguage.EN;
    }
    return PortalLanguage.NL;
  } catch (error) {
    console.error(error);
  }
};
