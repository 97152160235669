import { useIntl } from "react-intl";
import { z } from "zod";

export const useGivenNameSchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });

  const displayNameRegex = /^.+$/;
  const BLOCK_SQL = /.*[-="'<>%[\]{}\\]+.*/;
  const BLOCK_HTML = /<.*?>/;
  return z.object({
    givenName: z
      .string()
      .min(1, intlMessage("validation.given-name.min"))
      .max(30, intlMessage("validation.given-name.max"))
      .refine((value: string) => !BLOCK_SQL.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => !BLOCK_HTML.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => displayNameRegex.test(value), intlMessage("validation.special-characters")),
  });

};

export type TGivenNameSchema = z.infer<ReturnType<typeof useGivenNameSchema>>;

export const usePhoneSchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });

  const phoneRegex = /^[+]?[0-9 ]{8,15}$/;
  return z.object({
    phoneNumber: z
      .string()
      .min(8, intlMessage("validation.phone-number.length"))
      .max(15, intlMessage("validation.phone-number.length"))
      .regex(phoneRegex, intlMessage("validation.phone-number.format")),
  });
}

export type TPhoneSchema = z.infer<ReturnType<typeof usePhoneSchema>>;

export const useSalarySchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });

  return z.object({
    salary: z.coerce.number().int().positive(intlMessage("validation.salary.positive")),
    hours: z.coerce.number().int().positive(intlMessage("validation.hours.positive")),
  });
}

export type TSalarySchema = z.infer<ReturnType<typeof useSalarySchema>>;
