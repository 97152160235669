import { getAuthToken, revokeAuthToken, setAuthToken } from "./token";
import { getUuid, setUuid } from "./uuid";

export const isLoggedIn = () => !!getAuthToken() && !!getUuid();

export const login = (uuid: string, accessToken: string, refreshToken: string) => {
  setUuid(uuid);
  setAuthToken(accessToken, refreshToken);
};

export const logout = () => {
  revokeAuthToken();
  localStorage.clear();
};
