import { NonRetiredPension, RetiredPension } from "../lib/types";

export const retiredPensionValue = (retiredPension: RetiredPension, isGrossToggle: boolean) =>
  isGrossToggle ? retiredPension.grossAmount : retiredPension.netAmount;

export const nonRetiredPensionValue = (
  nonRetiredPension: NonRetiredPension,
  isOpgebouwdToggle: boolean,
  isAowToggle: boolean,
  isGrossToggle: boolean
) => {
  // the 2 booleans below can't be *true* at the same time but can be *false* at the same time
  if (isOpgebouwdToggle && isAowToggle) {
    return 0;
  }
  if (isOpgebouwdToggle) {
    return nonRetiredPension.grossAmount / 12;
  }
  if (isAowToggle && isGrossToggle) {
    return nonRetiredPension.opToBeReachedAOW / 12 + nonRetiredPension.aow / 12;
  }
  if (isAowToggle) {
    return nonRetiredPension.opToBeReachedAOWNet;
  }
  if (isGrossToggle) {
    return nonRetiredPension.opToBeReached / 12;
  }
  return nonRetiredPension.opToBeReachedNet;
};
