import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./react-query/client";

interface childrenType {
  children: React.ReactNode;
}

const Providers = ({ children }: childrenType) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default Providers;
