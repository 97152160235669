import { FormTextBlock, DataListRow, Popup } from "@fonk-gitlab/bpfd-portal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";

const PlannerOtherOptions: React.FC = () => {
  const data = usePlanner();
  const [info, setInfo] = useState(false);

  const onClickHandler = () => {
    setInfo(!info);
  };

  const inputData = [
    {
      index: "0",
      label: <FormattedMessage id="planner.partner" />,
      object1: {
        strikethrough: false,
        value: data?.planner?.pension?.rangesPP,
      },
    },
    {
      index: "1",
      label: <FormattedMessage id="planner.kid(s)" />,
      object1: {
        strikethrough: false,
        value: data?.planner?.pension?.rangesWZP,
      },
    },
  ];

  return (
    <>
      <FormTextBlock
        body={<FormattedMessage id="planner.divorce-info" />}
        heading={<FormattedMessage id="planner.divorce" />}
      />
      <FormTextBlock
        body={<FormattedMessage id="planner.disability-info" />}
        heading={<FormattedMessage id="planner.disability" />}
      />
      <FormTextBlock
        body={<FormattedMessage id="planner.deceased-info" />}
        heading={<FormattedMessage id="planner.deceased" />}
        showIcon
        onIconClick={onClickHandler}
      />
      <DataListRow header={""} inputData={inputData} columnVisibility={[true]} totalVisibleColumns={2} />
      <Popup
        open={info}
        heading={<FormattedMessage id="planner.deceased" />}
        body={<FormattedMessage id="planner.deceased-icon" />}
        onClose={onClickHandler}
        popupVariant="information"
      />
    </>
  );
};

export default PlannerOtherOptions;
