import {
  Dropdown,
  FormField,
  FormImportantMessage,
  FormTextBlock,
  Input,
  Popup,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { EarlierLaterFully } from "../../../lib/constants";
import { ageToCalendarCalculation, calendarToAgeCalculation } from "../../../utils/ageCalendarConverter";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";

const partialItems = Array.from({ length: 9 }, (_, i) => i + 1).map((item) => ({
  id: item.toString(),
  label: `${item * 10}%`,
  value: item,
}));

const EarlierOrLaterRetirementOption = () => {
  const intl = useIntl();
  const planner = usePlanner()?.planner;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const {
    control,
    watch,
    formState: { touchedFields },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const dropdownItems = [
    ...partialItems,
    { id: EarlierLaterFully.toString(), value: EarlierLaterFully, label: intl.formatMessage({ id: "utils.fully" }) },
  ];

  const fullyOrPartial = "retirementOption.earlierOrLaterRetirementOptions.fullyOrPartial";
  const partialAgeYearKey = "retirementOption.earlierOrLaterRetirementOptions.partialAgeYear";
  const partialAgeMonthKey = "retirementOption.earlierOrLaterRetirementOptions.partialAgeMonth";
  const partialCalendarYearKey = "retirementOption.earlierOrLaterRetirementOptions.partialCalendarYear";
  const partialCalendarMonthKey = "retirementOption.earlierOrLaterRetirementOptions.partialCalendarMonth";
  const fullAgeYearKey = "retirementOption.earlierOrLaterRetirementOptions.fullAgeYear";
  const fullAgeMonthKey = "retirementOption.earlierOrLaterRetirementOptions.fullAgeMonth";
  const fullCalendarYearKey = "retirementOption.earlierOrLaterRetirementOptions.fullCalendarYear";
  const fullCalendarMonthKey = "retirementOption.earlierOrLaterRetirementOptions.fullCalendarMonth";
  const fullAgeYearTouched = touchedFields?.retirementOption?.earlierOrLaterRetirementOptions?.fullAgeYear;
  const partialAgeYearTouched =
    touchedFields?.retirementOption?.earlierOrLaterRetirementOptions?.partialAgeYear;

  const [
    partialAgeYear,
    partialAgeMonth,
    partialCalendarYear,
    partialCalendarMonth,
    fullAgeYear,
    fullAgeMonth,
    fullCalendarYear,
    fullCalendarMonth,
  ] = watch([
    partialAgeYearKey,
    partialAgeMonthKey,
    partialCalendarYearKey,
    partialCalendarMonthKey,
    fullAgeYearKey,
    fullAgeMonthKey,
    fullCalendarYearKey,
    fullCalendarMonthKey,
  ]);

  const showPartialInputs = watch(fullyOrPartial)?.value !== EarlierLaterFully;
  const isEmptyField = !fullAgeYear;
  const dateOfBirth = planner?.person?.dateOfBirth ?? "";

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(partialAgeYear), month: Number(partialAgeMonth ?? 0) },
      { yearKey: partialCalendarYearKey, monthKey: partialCalendarMonthKey },
      { getValues, setValue }
    );
  }, [partialAgeYear, partialAgeMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(partialCalendarYear), month: Number(partialCalendarMonth?.value) },
      { yearKey: partialAgeYearKey, monthKey: partialAgeMonthKey },
      { getValues, setValue }
    );
  }, [partialCalendarYear, partialCalendarMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(fullAgeYear), month: Number(fullAgeMonth ?? 0) },
      { yearKey: fullCalendarYearKey, monthKey: fullCalendarMonthKey },
      { getValues, setValue }
    );
  }, [fullAgeYear, fullAgeMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(fullCalendarYear), month: Number(fullCalendarMonth?.value) },
      { yearKey: fullAgeYearKey, monthKey: fullAgeMonthKey },
      { getValues, setValue }
    );
  }, [fullCalendarYear, fullCalendarMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    if (!fullAgeYearTouched) return;
    trigger([fullAgeYearKey, fullAgeMonthKey, partialAgeMonthKey]);
  }, [partialAgeYear, fullAgeYearTouched, trigger]);

  useEffect(() => {
    if (!partialAgeYearTouched) return;
    trigger([partialAgeYearKey, partialAgeMonthKey, fullAgeMonthKey]);
  }, [fullAgeYear, partialAgeYearTouched, trigger]);

  useEffect(() => {
    trigger(fullAgeMonthKey);
  }, [partialAgeMonth, trigger]);

  useEffect(() => {
    trigger(partialAgeMonthKey);
  }, [fullAgeMonth, trigger]);

  return (
    <>
      <FormTextBlock
        body={<FormattedMessage id="planner.earlier-or-later.body-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.earlier-or-later.title" />}
        showIcon
        onIconClick={() => setShowInfo(true)}
      />
      {isEmptyField && (
        <FormImportantMessage
          body={<FormattedMessage id="planner.surrender.warning-text" />}
          className="col-span-12 mb-15 border-yellow-light-pastel"
        />
      )}
      <FormField
        className="col-span-12"
        id="input-id"
        labelText={<FormattedMessage id="planner.earlier-or-later.full-or-part-time" />}>
        <Dropdown items={dropdownItems} name={fullyOrPartial} control={control} placeholder="Placeholder" />
      </FormField>
      {showPartialInputs && (
        <div className="col-span-12 grid-cols-12">
          <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
            <Text className="col-span-12 font-semibold pb-2">
              <FormattedMessage id="planner.earlier-or-later.from-what-time" />
            </Text>
            <FormField
              className="col-span-6"
              id={partialAgeYearKey}
              labelText={<FormattedMessage id="utils.year-capital" />}>
              <Input
                placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
                id={partialAgeYearKey}
                name={partialAgeYearKey}
                control={control}
              />
            </FormField>
            <FormField
              className="col-span-6"
              id={partialAgeMonthKey}
              labelText={<FormattedMessage id="utils.month-capital" />}>
              <Input
                id={partialAgeMonthKey}
                name={partialAgeMonthKey}
                control={control}
                placeholder={intl.formatMessage(
                  { id: "utils.example" },
                  { value: `5 ${intl.formatMessage({ id: "utils.months" })}` }
                )}
              />
            </FormField>
          </div>
          <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
            <Text className="col-span-12 font-semibold pb-2">
              <FormattedMessage id="utils.per" />
            </Text>
            <FormField className="col-span-6" id="input-id">
              <PlannerMonthDropdown
                inputName={partialCalendarMonthKey}
                control={control}
                placeholder={intl.formatMessage({ id: "planner.select-month" })}
              />
            </FormField>
            <FormField
              className="col-span-6"
              id="input-id"
              labelText={<FormattedMessage id="utils.year-capital" />}>
              <Input
                id={partialCalendarYearKey}
                name={partialCalendarYearKey}
                control={control}
                placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
              />
            </FormField>
          </div>
        </div>
      )}

      <div className="col-span-12 grid-cols-12">
        <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
          <Text className="col-span-12 font-semibold pb-2">
            <FormattedMessage id="planner.surrender.full-pension-from" />
          </Text>
          <FormField
            className="col-span-6"
            id={fullAgeYearKey}
            labelText={<FormattedMessage id="utils.year-capital" />}>
            <Input
              id={fullAgeYearKey}
              name={fullAgeYearKey}
              control={control}
              placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
            />
          </FormField>
          <FormField
            className="col-span-6"
            id={fullAgeMonthKey}
            labelText={<FormattedMessage id="utils.month-capital" />}>
            <Input
              id={fullAgeMonthKey}
              name={fullAgeMonthKey}
              control={control}
              placeholder={intl.formatMessage(
                { id: "utils.example" },
                { value: `5 ${intl.formatMessage({ id: "utils.months" })}` }
              )}
            />
          </FormField>
        </div>
        <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
          <Text className="col-span-12 font-semibold pb-2">
            <FormattedMessage id="utils.per" />
          </Text>
          <PlannerMonthDropdown
            inputName={fullCalendarMonthKey}
            control={control}
            placeholder={intl.formatMessage({ id: "planner.select-month" })}
          />
          <FormField
            className="col-span-6"
            id={fullCalendarYearKey}
            labelText={<FormattedMessage id="utils.year-capital" />}>
            <Input
              id={fullCalendarYearKey}
              name={fullCalendarYearKey}
              control={control}
              placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
            />
          </FormField>
        </div>
      </div>

      {showInfo && (
        <Popup
          open={showInfo}
          body={<FormattedMessage id="planner.earlier-or-later.info" />}
          onClose={() => setShowInfo(false)}
          popupVariant="information"
        />
      )}
    </>
  );
};

export default EarlierOrLaterRetirementOption;
