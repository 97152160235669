import { LimitsAge } from "../lib/types";

export const getTotalMonths = (startDate: Date, endDate: Date): number => {
  return Math.abs(
    12 * (endDate.getFullYear() - startDate.getFullYear()) + (endDate.getMonth() - startDate.getMonth())
  );
};

export const extractYearAndMonth = (totalMonths: number): { years: number; months: number } => {
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  return { years, months };
};

export const limitAge = (birthDate: Date, retirementDate: Date): LimitsAge => {
  return {
    currentAge: extractYearAndMonth(getTotalMonths(birthDate, new Date()) + 1),
    retirementAge: extractYearAndMonth(getTotalMonths(birthDate, retirementDate) - 1),
  };
};

export const getCalendarByAge = (birthDate: Date, totalAge: number, totalMonths: number) => {
  const newDate = new Date(birthDate.getFullYear() + totalAge, birthDate.getMonth() + totalMonths, 1);
  return { yearNumber: newDate.getFullYear(), monthNumber: newDate.getMonth() };
};

export const getAgeByCalendar = (birthDate: Date, yearNumber: number, monthNumber: number) => {
  const newDate = new Date(yearNumber, monthNumber, 1);
  return extractYearAndMonth(getTotalMonths(birthDate, newDate));
};

export const getDateByTotalMonths = (birthDate: Date, totalMonths: number) => {
  const ageByYearsAndMonths = extractYearAndMonth(totalMonths);
  return new Date(
    birthDate.getFullYear() + ageByYearsAndMonths.years,
    birthDate.getMonth() + ageByYearsAndMonths.months,
    1
  );
};
