const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const numberToMonth = (monthNumber: number): string | null => {
  if (monthNumber < 1 || monthNumber > 12) {
    return null;
  }
  return months[monthNumber - 1];
};
