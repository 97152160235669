import { SituationOverviewPanel, Button, DataList, InfoToggle, Popup } from "@fonk-gitlab/bpfd-portal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { Situation, SitName, Amount } from "../../../lib/types";
import { DataRow } from "@fonk-gitlab/bpfd-portal/dist/components/molecules/dataList/DataList.types";
import { format } from "../../../utils/formatNumber";

const ageHandler = (ageInMonths: number) => {
  return (
    <FormattedMessage
      id="utils.years-months"
      values={{ years: Math.floor(ageInMonths / 12), months: ageInMonths % 12 }}
    />
  );
};

const dataRows = (curr: Situation[]): DataRow[] => {
  return curr
    .filter(Boolean)
    .sort((a, b) => a.ageInMonths - b.ageInMonths)
    .map((sit, i) => {
      const sitIndex = i.toString();
      const ageAmountRows = sit.amounts;
      const showTotal = sit.showTotal;
      const aow = ageAmountRows.find((a) => a.name === SitName.AOW);
      const pension = ageAmountRows.find((a) => a.name === SitName.PENSION);
      const total = ageAmountRows.find((a) => a.name === SitName.TOTAL);

      const isLast = (e: Amount) => ageAmountRows.indexOf(e) === ageAmountRows.length - 1;
      const moveToBottom = (e: Amount) =>
        ageAmountRows.push(ageAmountRows.splice(ageAmountRows.indexOf(e), 1)[0]);

      if (pension && !isLast(pension)) {
        moveToBottom(pension);
      }

      if (aow && !isLast(aow)) {
        moveToBottom(aow);
      }

      if (showTotal && total && !isLast(total)) {
        moveToBottom(total);
      }

      if (showTotal && total === undefined) {
        const safeNumber = (n: number | null) => {
          if (n === null) return null;
          return Number(n?.toFixed(2));
        };
        ageAmountRows.push({
          name: SitName.TOTAL,
          currentGross: safeNumber(sit.totalCurrentGross),
          currentNet: safeNumber(sit.totalCurrentNet),
          calculatedGross: safeNumber(sit.totalCalculatedGross),
          calculatedNet: safeNumber(sit.totalCalculatedNet),
          isTotal: showTotal,
          referenceValue: null,
        });
      }

      return {
        header: ageHandler(sit.ageInMonths),
        index: sitIndex,
        inputData: ageAmountRows.map((row, index) => {
          const indexAmount = `${sitIndex}${index}`;
          const isTotal = row.isTotal;
          const isCalcG = !!row.calculatedGross;
          const isCalcN = !!row.calculatedNet;

          const currGross = format(row.currentGross);
          const currNet = format(row.currentNet);
          const calcGross = format(row.calculatedGross);
          const calcNet = format(row.calculatedNet);

          return {
            index: indexAmount,
            label: row.name + (row.info ?? ""),
            object1: currGross ? { strikethrough: isCalcG, value: currGross, isTotal: isTotal } : null,
            object2: currNet ? { strikethrough: isCalcN, value: currNet, isTotal: isTotal } : null,
            object3: calcGross ? { strikethrough: false, value: calcGross, isTotal: isTotal } : null,
            object4: calcNet ? { strikethrough: false, value: calcNet, isTotal: isTotal } : null,
          };
        }),
      };
    });
};

const PlannerSituation: React.FC = () => {
  const [popup, setPopup] = useState(false);
  const [isNet, setIsNet] = useState(false);
  const data = usePlanner();

  const situations = data?.planner?.situations;

  const handlePopup = () => {
    setPopup(!popup);
  };

  const handleNetGross = () => {
    setIsNet(!isNet);
  };

  if (!situations) return <SituationOverviewPanel header={<FormattedMessage id="error.gross-net" />} />;

  return (
    <>
      <SituationOverviewPanel
        header={<FormattedMessage id="planner.current-situation" />}
        infoToggles={[
          <InfoToggle
            key="some-toggle-id"
            onIconClick={handlePopup}
            onChange={handleNetGross}
            icon="information"
            iconVisible
            id="some-toggle-id"
            label={<FormattedMessage id="planner.gross-net" />}
          />,
        ]}
        buttons={<Button size="md" text={<FormattedMessage id="planner.save-to-actions" />} />}
        dataList={
          <DataList
            itemHeaders={{
              header1: <FormattedMessage id="utils.age" />,
              header2: <FormattedMessage id="planner.current" />,
              header3: <FormattedMessage id="utils.net" />,
              header4: <FormattedMessage id="planner.calculated" />,
              header5: <FormattedMessage id="utils.net" />,
            }}
            dataRows={dataRows(situations)}
            columnVisibility={[true, isNet, true, isNet]}
            totalVisibleColumns={isNet ? 5 : 3}
          />
        }
      />
      <Popup
        open={popup}
        heading={<FormattedMessage id="planner.gross-net" />}
        body={<FormattedMessage id="planner.gross-net-info" />}
        onClose={handlePopup}
        popupVariant="information"
      />
    </>
  );
};

export default PlannerSituation;
