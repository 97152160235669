import { Accordion, AccordionItem } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import WorkingTimeOption from "../../molecules/plannerEmploymentOptions/WorkingTimeOption";
import EarningSalaryOption from "../../molecules/plannerEmploymentOptions/EarningSalaryOption";
import QuittingWorkOption from "../../molecules/plannerEmploymentOptions/QuittingWorkOption";
import { Controller, useFormContext } from "react-hook-form";

const PlannerEmploymentOptions: React.FC = () => {
  const planner = usePlanner()?.planner;
  const { control, resetField, setValue } = useFormContext();
  const workingTimeOptionKey = "employmentOptions.workingTimeOption";
  const workingTimeSelectedKey = "employmentOptions.workingTimeOption.isSelected";
  const earningSalaryOptionKey = "employmentOptions.earningSalaryOption";
  const earningSalarySelectedKey = "employmentOptions.earningSalaryOption.isSelected";
  const quittingWorkOptionKey = "employmentOptions.quittingWorkOption";
  const quittingWorkSelectedKey = "employmentOptions.quittingWorkOption.isSelected";

  return (
    <Accordion
      variant="checkbox"
      keyExtractor={(item) => item.key}
      accordionItems={[
        <Controller
          control={control}
          key={workingTimeSelectedKey}
          name={workingTimeSelectedKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id={workingTimeOptionKey}
              key="11"
              variant="checkbox"
              title={
                <FormattedMessage
                  id={
                    planner?.employment?.hours
                      ? "planner.employment.work-more-less-with-hours"
                      : "planner.employment.work-more-less"
                  }
                  values={{ hours: planner?.employment?.hours }}
                />
              }
              content={<WorkingTimeOption />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                onChange(isChecked);
                resetField(workingTimeOptionKey);
                setValue(workingTimeSelectedKey, isChecked);
              }}
            />
          )}
        />,
        <Controller
          control={control}
          key={earningSalarySelectedKey}
          name={earningSalarySelectedKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id={earningSalaryOptionKey}
              key="12"
              variant="checkbox"
              title={
                <FormattedMessage
                  id={
                    planner?.employment?.salary
                      ? "planner.employment.earn-more-less-with-salary"
                      : "planner.employment.earn-more-less"
                  }
                  values={{ salary: planner?.employment?.salary }}
                />
              }
              content={<EarningSalaryOption />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                onChange(isChecked);
                resetField(earningSalaryOptionKey);
                setValue(earningSalarySelectedKey, isChecked);
              }}
            />
          )}
        />,
        <Controller
          control={control}
          key={quittingWorkSelectedKey}
          name={quittingWorkSelectedKey}
          render={({ field: { onChange, value } }) => (
            <AccordionItem
              id={quittingWorkOptionKey}
              key="13"
              variant="checkbox"
              title={<FormattedMessage id="planner.employment.stop-working" />}
              content={<QuittingWorkOption />}
              isChecked={value}
              onCheckedChange={(isChecked) => {
                onChange(isChecked);
                resetField(quittingWorkOptionKey);
                setValue(quittingWorkSelectedKey, isChecked);
              }}
            />
          )}
        />,
      ]}
    />
  );
};

export default PlannerEmploymentOptions;
