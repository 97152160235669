import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "../utils/loginHandler";
import { AuthenticatedProvider } from "../context/AuthenticatedContext";
import { HREF } from "./routes";

interface ProtectedRouteProps {
  children: ReactNode;
}

const LoggedInRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  return isLoggedIn() ? (
    <AuthenticatedProvider>{children}</AuthenticatedProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const LoggedOutRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  return !isLoggedIn() ? <>{children}</> : <Navigate to={HREF.HOME} state={{ from: location }} replace />;
};

export { LoggedInRoute, LoggedOutRoute };
