import { FormattedMessage } from "react-intl";
import { numberToMonth } from "./numberToMonth";

const monthNumber: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const monthOptions = monthNumber.map((month) => ({
  id: month.toString(),
  label: <FormattedMessage id={`utils.month.${numberToMonth(month)}`} />,
  value: month.toString(),
}));
