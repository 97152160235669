import { ReactNode, createContext, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getToggles, getUser } from "../react-query/queries";
import { Person, Toggles } from "../lib/types";
import { useHandleAuthError } from "../components/organisms/ErrorHandlingHelper";

interface AuthenticatedContextProps {
  user: Person | undefined;
  toggles: Toggles | undefined;
  isLoading: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthenticatedContext = createContext<AuthenticatedContextProps | undefined>(undefined);

export const AuthenticatedProvider = ({ children }: AuthProviderProps) => {
  const {
    data: user,
    error: errorUser,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    select: (data) => data.person as Person,
  });

  const {
    data: toggles,
    error: errorToggles,
    isLoading: isTogglesLoading,
    refetch: refetchToggles,
  } = useQuery({
    queryKey: ["toggles"],
    queryFn: getToggles,
    select: (data) => data.toggles as Toggles,
  });

  useHandleAuthError(errorUser, refetchUser);
  useHandleAuthError(errorToggles, refetchToggles);

  const isLoading = isUserLoading || isTogglesLoading;

  const contextValue = useMemo(() => ({ user, toggles, isLoading }), [user, toggles, isLoading]);

  return <AuthenticatedContext.Provider value={contextValue}>{children}</AuthenticatedContext.Provider>;
};

export const useAuthentication = (): AuthenticatedContextProps | undefined => {
  const context = useContext(AuthenticatedContext);
  return context;
};
