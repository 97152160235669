import { Fragment } from "react/jsx-runtime";
import { PageLayout, TabCollection, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { getCommunicationPreferences } from "../../react-query/queries";
import { Preferences } from "../../lib/types";
import MyDataFirstTab from "../organisms/myData/MyDataFirstTab";
import MyDataSecondTab from "../organisms/myData/MyDataSecondTab";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { useHandleAuthError } from "../organisms/ErrorHandlingHelper";
import useScreenWidth from "../../hooks/useScreenWidth";

const UnderConstruction = () => {
  return (
    <div className="col-span-12 lg:col-span-10 lg:odd:col-start-2">
      <TextHeader subText="" text={<FormattedMessage id="utils.construction" />} />
    </div>
  );
};

const MyData = () => {
  const [activeKey, setActiveKey] = useState(0);
  const screenWidth = useScreenWidth();
  const data = useAuthentication();
  const {
    data: preferences,
    isLoading: comIsLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["communication", data?.user?.id],
    queryFn: getCommunicationPreferences,
    select: (data) => data.preferences[0] as Preferences,
    enabled: !!data?.user?.id,
  });

  useHandleAuthError(error, refetch);

  const paymentDetailsId = screenWidth >= 1024 ? "my-data.payment-details" : "my-data.payment-details-mobile";

  const tabs = [
    <FormattedMessage id="my-data.my-data-tab" key="my-data.my-data" />,
    <FormattedMessage id="my-data-correspondence-settings" key="my-data-correspondence-settings" />,
    <FormattedMessage id={paymentDetailsId} key="my-data.payment-details" />,
  ];

  const isUnderConstruction = true;

  const handleSetActiveKey = (key: number) => {
    setActiveKey(key);
  };

  if (data?.isLoading || comIsLoading) {
    return <Loading />;
  }

  if (!data?.user) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      <Fragment key=".0">
        <TextHeader
          subText=""
          tabCollection={
            <TabCollection
              activeKey={activeKey}
              className="pt-20"
              contents={tabs}
              handleSetActiveKey={handleSetActiveKey}
            />
          }
          text={<FormattedMessage id="my-data.my-data-tab" />}
        />
        <div className="container py-20 flex-grow">
          <div className="grid grid-cols-12 gap-x-0 md:gap-x-2 gap-y-2">
            {activeKey === 0 && <MyDataFirstTab user={data.user} />}
            {activeKey === 1 &&
              (isUnderConstruction || !preferences ? (
                <UnderConstruction />
              ) : (
                <MyDataSecondTab preferences={preferences} user={data.user} />
              ))}
            {activeKey === 2 && <UnderConstruction />}
          </div>
        </div>
      </Fragment>
    </PageLayout>
  );
};

export default MyData;
