import { Accordion, AccordionItem, Button } from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { ParticipationStatus, Retirement } from "../../../lib/types";
import { limitAge } from "../../../utils/plannerCalendar";
import { getRetirementLimits } from "../../../utils/retirement";
import PlannerEmploymentOptions from "./PlannerEmploymentOptions";
import PlannerOtherOptions from "./PlannerOtherOptions";
import PlannerRetirementOptions from "./PlannerRetirementOptions";
import { EarlierLaterFully } from "../../../lib/constants";
import { usePlannerSchema, TPlannerSchema } from "../../../hooks/schemas/plannerSchema";

const PlannerOptions: React.FC = () => {
  const intl = useIntl();
  const planner = usePlanner()?.planner;
  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const isEmploymentActive = isActive || planner?.employment?.salary;
  // dateOfBirth is checked in Planner.tsx and retirementDate is calculated with dateOfBirth
  // 0 is used to avoid @typescript-eslint/no-non-null-assertion error
  const retirementDate = planner?.participation?.defaultRetirementDate ?? "0";
  const dateOfBirth = planner?.person?.dateOfBirth ?? "0";
  const retirement = planner?.retirement ?? ({} as Retirement);
  const methods = useForm<TPlannerSchema>({
    mode: "onChange",
    resolver: zodResolver(
      usePlannerSchema({
        isActive,
        limitAge: limitAge(new Date(dateOfBirth), new Date(retirementDate)),
        retirementYear: new Date(retirementDate).getFullYear(),
        retirementOptionsLimits: {
          ...getRetirementLimits({
            retirement,
            participantDOB: dateOfBirth,
          }),
        },
      })
    ),
    defaultValues: {
      employmentOptions: {
        workingTimeOption: {
          isSelected: false,
        },
        earningSalaryOption: {
          isSelected: false,
        },
      },
      retirementOption: {
        surrenderOptions: {
          isSurrenderSelected: false,
          isRefrainFromSurrenderSelected: false,
        },
        earlierOrLaterRetirementOptions: {
          isEarlierOrLaterRetirementSelected: false,
          fullyOrPartial: {
            id: EarlierLaterFully.toString(),
            label: intl.formatMessage({ id: "utils.fully" }),
            value: EarlierLaterFully,
          },
        },
        exchangeOption: {
          isSelected: false,
        },
        bridgingOption: {
          isSelected: false,
          maxExchangeAowAmount: retirement?.maxExchangeBridging ?? 0,
        },
        highLowLowHigh: {
          isSelected: false,
        },
      },
    },
  });
  const { isSubmitting } = methods.formState;

  const onSubmit = (data: TPlannerSchema) => console.log("SubmitData", data);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Accordion
          keyExtractor={(item) => item.key}
          className="gap-y-2 flex flex-col"
          accordionItems={[
            isEmploymentActive && (
              <AccordionItem
                id="employmentOptions"
                key="1"
                title={<FormattedMessage id="planner.employment-heading-section" />}
                content={<PlannerEmploymentOptions />}
              />
            ),
            <AccordionItem
              id="retirementOptions"
              key="2"
              title={<FormattedMessage id="planner.retirement-heading-section" />}
              content={<PlannerRetirementOptions />}
            />,
            <AccordionItem
              id="otherOptions"
              key="3"
              title={<FormattedMessage id="planner.other-heading-section" />}
              content={<PlannerOtherOptions />}
            />,
          ].filter(Boolean)}
        />
        <div className="pt-4">
          <Button
            ariaLabel="button"
            size="md"
            text={<FormattedMessage id="planner.calculate" />}
            type="submit"
            disabled={!!isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default PlannerOptions;
