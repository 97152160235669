import { FieldValues } from "react-hook-form";
import { EarlierLaterFully } from "../lib/constants";
import { Employment, PlannerBridgingPayload } from "../lib/types";
import { RadioButtonProps } from "@fonk-gitlab/bpfd-portal";

export const getPlannerBridgingPayload = (
  dataForm: FieldValues,
  employmentInfo: Employment
): PlannerBridgingPayload => {
  const fullyOrPartialValue =
    dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullyOrPartial?.value;
  const isExchangeSelected = dataForm?.retirementOption?.exchangeOption?.isSelected;
  const isHighLowSelected = dataForm?.retirementOption?.highLowLowHigh?.isSelected;

  return {
    salary: employmentInfo?.salary,
    hours: employmentInfo?.hours,
    employmentOptions: {
      isWorkingTimeSelected: dataForm?.employmentOptions?.workingTimeOption?.isSelected,
      isEarningSalarySelected: dataForm?.employmentOptions?.earningSalaryOption?.isSelected,
    },
    retirementOptions: {
      earlyOrLater: {
        isSelected:
          dataForm.retirementOption?.earlierOrLaterRetirementOptions?.isEarlierOrLaterRetirementSelected,
        percentage: fullyOrPartialValue,
        partlyYears:
          fullyOrPartialValue !== EarlierLaterFully
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.partialAgeYear
            : null,
        partlyMonths:
          fullyOrPartialValue !== EarlierLaterFully
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.partialAgeMonth
            : null,
        fullyYears:
          fullyOrPartialValue === EarlierLaterFully
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullAgeYear
            : null,
        fullyMonths:
          fullyOrPartialValue === EarlierLaterFully
            ? dataForm?.retirementOption?.earlierOrLaterRetirementOptions?.fullAgeMonth
            : null,
      },
      exchange: {
        isSelected: isExchangeSelected,
        exchangeType: isExchangeSelected ? dataForm?.retirementOption?.exchangeOption?.exchangeType : null,
        exchangeAmount: isExchangeSelected
          ? dataForm?.retirementOption?.exchangeOption?.exchangeAmount
          : null,
      },
      highLow: {
        isSelected: isHighLowSelected,
        type: isHighLowSelected
          ? dataForm?.retirementOption?.highLowLowHigh?.exchangeOptions?.find(
              (item: RadioButtonProps) => item.checked
            )?.value
          : null,
        months: isHighLowSelected ? dataForm?.retirementOption?.highLowLowHigh?.numberOfMonths : null,
      },
    },
  };
};
