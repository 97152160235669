import { FieldValues, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { getAgeByCalendar, getCalendarByAge } from "./plannerCalendar";
import { selectedDropdownItem } from "./selectedDropdownItem";
import { monthOptions } from "./MonthOptions";

export interface FormMethods {
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

interface FieldsName {
  yearKey: string;
  monthKey: string;
}

interface NewValue {
  year: number;
  month: number;
}

export const ageToCalendarCalculation = (
  dateOfBirth: Date,
  newValue: NewValue,
  calendarKey: FieldsName,
  formMethods: FormMethods
) => {
  if (isNaN(newValue.year) || isNaN(newValue.month) || newValue.month < 0 || newValue.month > 11) {
    return;
  }

  const newCalendar = getCalendarByAge(dateOfBirth, newValue.year, newValue.month);
  const newYearValue = newCalendar?.yearNumber?.toString();
  const newMonthValue = (newCalendar?.monthNumber + 1)?.toString();

  const oldYearValue = formMethods.getValues(calendarKey.yearKey)?.toString();
  const oldMonth = formMethods.getValues(calendarKey.monthKey);
  const oldMonthValue = oldMonth === undefined ? "" : oldMonth.value;

  const isYearDiff = newYearValue !== oldYearValue;
  const isMonthDiff = newMonthValue !== oldMonthValue;

  if (isYearDiff || isMonthDiff) {
    formMethods.setValue(calendarKey.monthKey, selectedDropdownItem(monthOptions, newMonthValue), {
      shouldValidate: true,
    });
    formMethods.setValue(calendarKey.yearKey, newCalendar?.yearNumber, { shouldValidate: true });
  }
};

export const calendarToAgeCalculation = (
  dateOfBirth: Date,
  newValue: NewValue,
  ageKey: FieldsName,
  formMethods: FormMethods
) => {
  if (isNaN(newValue.year) || !newValue.month || newValue.year - dateOfBirth.getFullYear() < 0) {
    return;
  }

  const totalAge = getAgeByCalendar(dateOfBirth, newValue.year, newValue.month - 1);
  const isYearDiff = totalAge?.years !== formMethods.getValues(ageKey.yearKey);
  const newMonthValue = totalAge?.months;
  const oldMonthValue = formMethods.getValues(ageKey.monthKey);
  const isMonthDiff = oldMonthValue === "" && newMonthValue === 0 ? false : newMonthValue !== oldMonthValue;

  if (isYearDiff || isMonthDiff) {
    formMethods.setValue(ageKey.yearKey, totalAge?.years, { shouldValidate: true });
    formMethods.setValue(ageKey.monthKey, newMonthValue, { shouldValidate: true });
  }
};
