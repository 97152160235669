import { PageLayout, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react/jsx-runtime";

const Actions = () => {
  return (
    <PageLayout>
      <Fragment key=".0">
        <TextHeader subText={<FormattedMessage id="utils.construction-page" />} text="" />
      </Fragment>
    </PageLayout>
  );
};

export default Actions;
