import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, PageLayout, Popup, TextHeader } from "@fonk-gitlab/bpfd-portal";
import PlannerSituation from "../organisms/planner/PlannerSituation";
import PlannerPredictionHeader from "../organisms/planner/PlannerPredictionHeader";
import PlannerOptions from "../organisms/planner/PlannerOptions";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { PlannerProvider, usePlanner } from "../../context/PlannerContext";
import { ParticipationStatus } from "../../lib/types";

const PlannerConsumer: React.FC = () => {
  const data = usePlanner();
  const [hasPrevScenario, setHasPrevScenario] = useState<boolean>(!!data?.planner?.scenario);
  const isActive = data?.planner?.participation?.status === ParticipationStatus.ACTIVE;
  const birthDay = data?.planner?.person?.dateOfBirth;

  const handlePopup = () => {
    setHasPrevScenario(!hasPrevScenario);
  };

  const handleRetrieve = () => {
    handlePopup();
    console.log("retrieve");
  };

  const handleDelete = () => {
    handlePopup();
    console.log("delete");
  };

  if (data?.isLoading) {
    return <Loading />;
  }

  if (!data?.planner || !birthDay) {
    return <RequestError />;
  }

  return (
    <>
      <PageLayout>
        <Fragment key=".1">
          <TextHeader subText={<FormattedMessage id="utils.construction-page" />} text="" />
          <div className="container py-20 flex-grow">
            <PlannerPredictionHeader isActive={isActive} employment={data.planner.employment} />
            <div className="grid grid-cols-12 gap-x-1 md:gap-x-2">
              <div className="lg:col-span-5 col-span-12 lg:col-start-2 order-2 lg-order-1 mb-20 lg:mb-0">
                <PlannerOptions />
              </div>
              <div className="col-span-12 lg:col-span-5 order-1 lg:order-2 mb-20 lg:mb-0">
                <PlannerSituation />
              </div>
            </div>
          </div>
        </Fragment>
      </PageLayout>
      <Popup
        open={hasPrevScenario}
        body={"Do you want to use the last scenario?"}
        buttons={
          <>
            <Button
              ariaLabel="yes"
              size="md"
              text={<FormattedMessage id="utils.yes" />}
              onClick={handleRetrieve}
            />
            <Button
              ariaLabel="no"
              size="md"
              text={<FormattedMessage id="utils.no" />}
              onClick={handleDelete}
            />
          </>
        }
        onClose={handlePopup}
        popupVariant="confirmation"
      />
    </>
  );
};

const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerConsumer />
    </PlannerProvider>
  );
};

export default Planner;
