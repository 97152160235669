import { FormField, FormImportantMessage, FormTextBlock, Input } from "@fonk-gitlab/bpfd-portal";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { useEffect } from "react";
import { ageToCalendarCalculation, calendarToAgeCalculation } from "../../../utils/ageCalendarConverter";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";

const QuittingWorkOption = () => {
  const planner = usePlanner()?.planner;
  const intl = useIntl();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const ageYearKey = "employmentOptions.quittingWorkOption.ageYear";
  const ageMonthKey = "employmentOptions.quittingWorkOption.ageMonth";
  const calendarMonthKey = "employmentOptions.quittingWorkOption.calendarMonth";
  const calendarYearKey = "employmentOptions.quittingWorkOption.calendarYear";

  const [watchedAgeYear, watchedAgeMonth, watchedCalendarMonth, watchedCalendarYear] = watch([
    ageYearKey,
    ageMonthKey,
    calendarMonthKey,
    calendarYearKey,
  ]);

  const isDisplayWarning = !watchedAgeYear;
  const dateOfBirth = planner?.person?.dateOfBirth ?? "";

  useEffect(() => {
    ageToCalendarCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedAgeYear), month: Number(watchedAgeMonth ?? 0) },
      { yearKey: calendarYearKey, monthKey: calendarMonthKey },
      { getValues, setValue }
    );
  }, [watchedAgeYear, watchedAgeMonth, dateOfBirth, getValues, setValue]);

  useEffect(() => {
    calendarToAgeCalculation(
      new Date(dateOfBirth),
      { year: Number(watchedCalendarYear), month: Number(watchedCalendarMonth?.value) },
      { yearKey: ageYearKey, monthKey: ageMonthKey },
      { getValues, setValue }
    );
  }, [watchedCalendarYear, watchedCalendarMonth, dateOfBirth, getValues, setValue]);

  return (
    <>
      {isDisplayWarning && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.warning.required-fields" />}
          className="col-span-12 mb-15"
        />
      )}
      <div className="col-span-12 text-form text-black font-semibold">
        <span>
          <FormattedMessage id="planner.from-what" />
        </span>
      </div>
      <FormField
        className="col-span-6"
        id={ageYearKey}
        labelText={<FormattedMessage id="planner.year-label" />}>
        <Input
          id={ageYearKey}
          name={ageYearKey}
          placeholder={intl.formatMessage({ id: "planner.year-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <FormField
        className="col-span-6"
        id={ageMonthKey}
        labelText={<FormattedMessage id="planner.month-label" />}>
        <Input
          id={ageMonthKey}
          name={ageMonthKey}
          placeholder={intl.formatMessage({ id: "planner.month-placeholder" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <div className="col-span-12 text-form text-black font-semibold">
        <span>Per</span>
      </div>
      <PlannerMonthDropdown
        inputName={calendarMonthKey}
        placeholder={intl.formatMessage({ id: "planner.select-month" })}
        control={control}
      />
      <FormField
        className="col-span-6"
        id={calendarYearKey}
        labelText={<FormattedMessage id="planner.year-label" />}>
        <Input
          id={calendarYearKey}
          name={calendarYearKey}
          placeholder={intl.formatMessage({ id: "planner.select-year" })}
          control={control}
          disabled={isSubmitting}
        />
      </FormField>
      <FormTextBlock
        body={
          <FormattedMessage
            id="planer.continue-work-contact"
            values={{ phoneNumber: planner?.employment?.providerParticipantPhoneNumber }}
          />
        }
        heading={<FormattedMessage id="planner.continue-work-header" />}
        className="col-start-1 col-end-12"
      />
    </>
  );
};

export default QuittingWorkOption;
