import {
  DropdownItem,
  FormField,
  FormTextBlock,
  Input,
  Popup,
  RadioButtons,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Controller, FieldErrors, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { TPlannerSchema } from "../../../hooks/schemas/plannerSchema";
import { EarlierLaterFully } from "../../../lib/constants";
import { ageToCalendarCalculation } from "../../../utils/ageCalendarConverter";
import { getCalendarByAge, getTotalMonths } from "../../../utils/plannerCalendar";
import PlannerMonthDropdown from "../../atoms/PlannerMonthDropdown";

const HighLowLowHighOption = () => {
  const intl = useIntl();
  const planner = usePlanner()?.planner;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const exchangeOptions = [
    {
      checked: false,
      id: "highThanLow",
      label: intl.formatMessage({ id: "planner.exchange-overtime.high-than-low" }),
      value: "HL",
    },
    {
      checked: false,
      id: "lowThanHigh",
      label: intl.formatMessage({ id: "planner.exchange-overtime.low-than-high" }),
      value: "LH",
    },
  ];

  const typedErrors: FieldErrors<TPlannerSchema> = errors;
  const exchangeOptionsKey = "retirementOption.highLowLowHigh.exchangeOptions";
  const numberOfMonthsKey = "retirementOption.highLowLowHigh.numberOfMonths";
  const calendarMonthKey = "retirementOption.highLowLowHigh.calendarMonth";
  const calendarYearKey = "retirementOption.highLowLowHigh.calendarYear";
  const fullyOrPartialKey = "retirementOption.earlierOrLaterRetirementOptions.fullyOrPartial";
  const fullCalendarYearKey = "retirementOption.earlierOrLaterRetirementOptions.fullCalendarYear";
  const fullCalendarMonthKey = "retirementOption.earlierOrLaterRetirementOptions.fullCalendarMonth";
  const earlierOrLaterIsSelectedKey =
    "retirementOption.earlierOrLaterRetirementOptions.isEarlierOrLaterRetirementSelected";
  const partialCalendarYearKey = "retirementOption.earlierOrLaterRetirementOptions.partialCalendarYear";
  const partialCalendarMonthKey = "retirementOption.earlierOrLaterRetirementOptions.partialCalendarMonth";
  const maxDefaultRetirementAgeInMonths = planner?.retirement?.maxDefaultRetirementAgeInMonths ?? 0;
  const dateOfBirth = planner?.person?.dateOfBirth ?? "";
  const retirementStartAgeYear = planner?.scenario?.retirementStartYear ?? 0;
  const retirementStartAgeMonth = planner?.scenario?.retirementStartMonth ?? 0;
  const partlyRetirementPercentage = planner?.scenario?.partlyRetirementPercentage;
  const partlyRetirementStartAgeMonth = planner?.scenario?.partlyRetirementStartMonth ?? 0;
  const partlyRetirementStartAgeYear = planner?.scenario?.partlyRetirementStartYear ?? 0;

  const [
    fullyOrPartial,
    fullCalendarYear,
    fullCalendarMonth,
    earlierOrLaterIsSelected,
    partialCalendarYear,
    partialCalendarMonth,
  ] = watch([
    fullyOrPartialKey,
    fullCalendarYearKey,
    fullCalendarMonthKey,
    earlierOrLaterIsSelectedKey,
    partialCalendarYearKey,
    partialCalendarMonthKey,
  ]);

  const validStartDate = useMemo(() => {
    const defaultRetirementAgeInMonths = maxDefaultRetirementAgeInMonths ?? 0;
    const dob = new Date(dateOfBirth);
    const defaultStartYear = Math.floor(defaultRetirementAgeInMonths / 12) + dob.getFullYear();
    const defaultStartMonth = (defaultRetirementAgeInMonths % 12) + dob.getMonth();
    const retirementCalendar = getCalendarByAge(dob, retirementStartAgeYear, retirementStartAgeMonth);
    const partlyRetirementCalendar = getCalendarByAge(
      dob,
      partlyRetirementStartAgeMonth,
      partlyRetirementStartAgeYear
    );

    let startYear;
    let startMonth;

    if (partlyRetirementPercentage) {
      if (partlyRetirementPercentage === 100) {
        startYear = retirementCalendar.yearNumber;
        startMonth = retirementCalendar.monthNumber;
      } else {
        startYear = partlyRetirementCalendar.yearNumber;
        startMonth = partlyRetirementCalendar.monthNumber;
      }
    } else {
      startYear = defaultStartYear;
      startMonth = defaultStartMonth;
    }

    if (earlierOrLaterIsSelected) {
      if (fullyOrPartial.value === EarlierLaterFully && fullCalendarYear && fullCalendarMonth) {
        startYear = Number(fullCalendarYear);
        startMonth = Number(fullCalendarMonth?.value) - 1;
      }

      if (fullyOrPartial !== EarlierLaterFully && partialCalendarYear && partialCalendarMonth) {
        startYear = Number(partialCalendarYear);
        startMonth = Number(partialCalendarMonth?.value) - 1;
      }
    }

    return new Date(startYear, startMonth);
  }, [
    maxDefaultRetirementAgeInMonths,
    fullCalendarYear,
    fullCalendarMonth,
    earlierOrLaterIsSelected,
    partialCalendarYear,
    partialCalendarMonth,
    dateOfBirth,
    fullyOrPartial,
    partlyRetirementPercentage,
    partlyRetirementStartAgeMonth,
    partlyRetirementStartAgeYear,
    retirementStartAgeMonth,
    retirementStartAgeYear,
  ]);

  const handleNumberOfMonthsChange = (value: string) => {
    setValue(numberOfMonthsKey, value, { shouldValidate: true });

    ageToCalendarCalculation(
      new Date(validStartDate.getFullYear(), validStartDate.getMonth()),
      { year: Math.floor(Number(value) / 12), month: Number(value) % 12 },
      { yearKey: calendarYearKey, monthKey: calendarMonthKey },
      { getValues, setValue }
    );
  };

  const handleCalendarChange = (calendarYear: string, calendarMonth: DropdownItem) => {
    const dob = new Date(dateOfBirth);
    if (Number(calendarYear) - dob.getFullYear() < 0) return;

    const startDateInMonths = getTotalMonths(validStartDate, new Date(planner?.person.dateOfBirth ?? 0));

    const expectStartDateInMonths = getTotalMonths(
      new Date(Number(calendarYear), Number(calendarMonth?.value) - 1),
      new Date(planner?.person.dateOfBirth ?? 0)
    );

    const monthDiff = expectStartDateInMonths - startDateInMonths;

    if (!isNaN(monthDiff)) {
      setValue(numberOfMonthsKey, monthDiff, { shouldValidate: true });
    }
  };

  const handleYearCalendarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(calendarYearKey, value, { shouldValidate: true });
    handleCalendarChange(value, getValues(calendarMonthKey));
  };

  const handleMonthCalendarChange = (value: DropdownItem) => {
    setValue(calendarMonthKey, value);
    handleCalendarChange(getValues(calendarYearKey), value);
  };

  useEffect(() => {
    const numberOfMonths = getValues(numberOfMonthsKey);
    handleNumberOfMonthsChange(numberOfMonths);
  }, [validStartDate]);

  return (
    <>
      <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
        <FormTextBlock
          body={<FormattedMessage id="planner.exchange-overtime.body-text-first" />}
          className="col-span-12"
          showIcon
          onIconClick={() => setShowInfo(true)}
          heading={<FormattedMessage id="planner.exchange-overtime.heading-text" />}
        />
        <FormTextBlock
          body={<FormattedMessage id="planner.exchange-overtime.body-text-second" />}
          className="col-span-12"
        />
      </div>
      <div className="w-full col-span-12 grid-cols-12 grid gap-x-5">
        <FormField
          className="col-span-12"
          id="radioFormField"
          labelText={<FormattedMessage id="planner.exchange-overtime.you-wish-first" />}>
          <RadioButtons name={exchangeOptionsKey} control={control} options={exchangeOptions} />
        </FormField>
        <Text className="col-span-12 font-semibold pb-2">
          <FormattedMessage id="planner.exchange-overtime.during-months" />
        </Text>
        <Controller
          control={control}
          name={numberOfMonthsKey}
          render={({ field: { value } }) => (
            <FormField
              id={numberOfMonthsKey}
              labelText={<FormattedMessage id="utils.months-capital" />}
              className="col-span-12">
              <Input
                id={numberOfMonthsKey}
                name={numberOfMonthsKey}
                value={value}
                placeholder={intl.formatMessage({ id: "utils.example" }, { value: "12" })}
                onChange={(e) => handleNumberOfMonthsChange(e.target.value)}
                error={!!typedErrors.retirementOption?.highLowLowHigh?.numberOfMonths}
                feedbackMessage={typedErrors.retirementOption?.highLowLowHigh?.numberOfMonths?.message}
              />
            </FormField>
          )}
        />

        <Text className="col-span-12 font-semibold pb-2">
          <FormattedMessage id="utils.to" />
        </Text>
        <Controller
          name={calendarMonthKey}
          control={control}
          render={({ field: { value } }) => (
            <PlannerMonthDropdown
              inputName={calendarMonthKey}
              placeholder={intl.formatMessage({ id: "planner.select-month" })}
              onSelect={handleMonthCalendarChange}
              value={value}
            />
          )}
        />

        <Controller
          control={control}
          name={calendarYearKey}
          render={({ field: { value } }) => (
            <FormField
              className="col-span-6"
              id={calendarYearKey}
              labelText={<FormattedMessage id="utils.year-capital" />}>
              <Input
                id={calendarYearKey}
                name={calendarYearKey}
                value={value}
                placeholder={intl.formatMessage({ id: "utils.example" }, { value: "65" })}
                onChange={handleYearCalendarChange}
                error={!!typedErrors.retirementOption?.highLowLowHigh?.calendarYear}
                feedbackMessage={typedErrors.retirementOption?.highLowLowHigh?.calendarYear?.message}
              />
            </FormField>
          )}
        />
      </div>

      {showInfo && (
        <Popup
          open={showInfo}
          body={<FormattedMessage id="planner.exchange-overtime.info" />}
          onClose={() => setShowInfo(false)}
          popupVariant="information"
        />
      )}
    </>
  );
};

export default HighLowLowHighOption;
