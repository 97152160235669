import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isLoggedIn, login } from "../../utils/loginHandler";
import { HREF } from "../../routes/routes";

const Callback = () => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const responseData = queryParameters.get("data");
  const originalState = localStorage.getItem("state");

  useEffect(() => {
    try {
      if (isLoggedIn()) {
        console.log("Already logged in, redirecting to homepage.");
        navigate(HREF.HOME);
        return;
      }

      if (!responseData) {
        console.error("No data received, redirecting to login.");
        navigate(HREF.LOGIN);
        return;
      }

      const response = JSON.parse(atob(responseData));
      if (!response?.success) {
        throw new Error(response?.error);
      }

      if (response.state !== originalState) {
        throw new Error("State does not match.");
      }

      if (!response?.accessToken || !response?.refreshToken) {
        throw new Error("Je sessie is verlopen of ongeldig. Log opnieuw in.");
      }

      login(response?.userId, response?.accessToken, response?.refreshToken);
      navigate(HREF.HOME);
      return;
    } catch (error) {
      navigate(HREF.LOGIN, { state: { error: (error as Error).message } });
      return;
    }
  }, [responseData, navigate, originalState]);

  return null;
};

export default Callback;
