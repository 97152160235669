import { ReactNode, useMemo, useContext, createContext } from "react";
import { Planner } from "../lib/types";
import { useQuery } from "@tanstack/react-query";
import { getPlanner } from "../react-query/queries";
import { useHandleAuthError } from "../components/organisms/ErrorHandlingHelper";

interface PlannerContextProps {
  planner: Planner | undefined;
  isLoading: boolean;
}

interface PlannerProviderProps {
  children: ReactNode;
}

export const PlannerContext = createContext<PlannerContextProps | undefined>(undefined);

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  const {
    data: planner,
    error: errorPlanner,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["planner"],
    queryFn: getPlanner,
    select: (data) => data.planner as Planner,
  });

  useHandleAuthError(errorPlanner, refetch);

  const contextValue = useMemo(() => ({ planner, isLoading }), [planner, isLoading]);

  return <PlannerContext.Provider value={contextValue}>{children}</PlannerContext.Provider>;
};

export const usePlanner = (): PlannerContextProps | undefined => {
  const context = useContext(PlannerContext);
  return context;
};
