import { InformationPanel, Button } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { Person } from "../../../lib/types";
import { HREF } from "../../../routes/routes";
import { reverseDate } from "../../../utils/reverseDate";

interface MyDataUserDetailsProps {
  user: Person;
}

const MyDataUserDetails: React.FC<MyDataUserDetailsProps> = ({ user }) => {
  const userDetails = (user: Person) => {
    return [
      {
        label: <FormattedMessage id="my-data.relation-id" />,
        input: user.personId,
      },
      {
        label: <FormattedMessage id="utils.bsn" />,
        input: user.bsn,
      },
      {
        label: <FormattedMessage id="utils.lastName" />,
        input: user.lastName,
      },
      {
        label: <FormattedMessage id="utils.firstName" />,
        input: user.firstName,
      },
      {
        label: <FormattedMessage id="utils.givenName" />,
        input: user.givenName,
      },
      {
        label: <FormattedMessage id="utils.marital-status" />,
        input: user.maritalStatus || <FormattedMessage id="utils.single" />,
      },
      {
        label: <FormattedMessage id="utils.birthdate" />,
        input: reverseDate(user.dateOfBirth),
      },
      {
        label: <FormattedMessage id="utils.gender" />,
        input: <FormattedMessage id={`utils.gender.${user.gender}`} />,
      },
    ];
  };

  return (
    <InformationPanel
      buttons={<Button size="md" text={<FormattedMessage id="utils.edit" />} href={HREF.USER_DETAILS_FORM} />}
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data.my-data-tab" />}
      informationDetails={userDetails(user)}
    />
  );
};

export default MyDataUserDetails;
