import { Dropdown, DropdownItem, FormField } from "@fonk-gitlab/bpfd-portal";
import { Control, FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { monthOptions } from "../../utils/MonthOptions";

interface PlannerMonthDropdownProps {
  inputName: string;
  placeholder: string;
  control?: Control<FieldValues>;
  onSelect?: (value: DropdownItem) => void;
  value?: DropdownItem;
}

const PlannerMonthDropdown: React.FC<PlannerMonthDropdownProps> = ({
  inputName,
  placeholder,
  control,
  onSelect,
  value,
}) => {
  return (
    <FormField
      className="col-span-6"
      id={inputName}
      labelText={<FormattedMessage id="planner.month-label" />}>
      <Dropdown
        items={monthOptions}
        name={inputName}
        placeholder={placeholder}
        control={control}
        onSelected={onSelect}
        value={value}
      />
    </FormField>
  );
};

export default PlannerMonthDropdown;
