import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import Documents from "../components/pages/Documents";
import Homepage from "../components/pages/Homepage";
import NotFoundPage from "../components/pages/NotFoundPage";
import Actions from "../components/pages/Actions";
import Planner from "../components/pages/Planner";
import MyData from "../components/pages/MyData";
import Contact from "../components/pages/Contact";
import { ContactDetailsForm } from "../components/forms/ContactDetailsForm";
import { UserDetailsForm } from "../components/forms/UserDetailsForm";
import Login from "../components/pages/Login";
import Callback from "../components/pages/Callback";
import { LoggedInRoute, LoggedOutRoute } from "./ProtectedRoute";
import { Logout } from "../components/pages/Logout";
import { isLoggedIn } from "../utils/loginHandler";
import LoginWrapper from "../components/pages/LoginWrapper";
import { HREF } from "./routes";

const childPath = (path: string) => {
  return path.substring(1);
};

const routes = [
  {
    path: HREF.HOME,
    element: (
      <LoggedInRoute>
        <App />
      </LoggedInRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: HREF.EMPTY,
        element: <Homepage />,
      },
      {
        path: childPath(HREF.PLANNER),
        element: <Planner />,
      },
      {
        path: childPath(HREF.ACTIONS),
        element: <Actions />,
      },
      {
        path: childPath(HREF.DOCUMENTS),
        element: <Documents />,
      },
      {
        path: childPath(HREF.CONTACT),
        element: <Contact />,
      },
      {
        path: childPath(HREF.MY_DATA),
        element: <MyData />,
      },
      {
        path: childPath(HREF.CONTACT_DETAILS_FORM),
        element: <ContactDetailsForm />,
      },
      {
        path: childPath(HREF.USER_DETAILS_FORM),
        element: <UserDetailsForm />,
      },
    ],
  },
  {
    path: childPath(HREF.LOGIN),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Login />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: childPath(HREF.CALLBACK),
    element: (
      <LoggedOutRoute>
        <Callback />
      </LoggedOutRoute>
    ),
  },
  {
    path: childPath(HREF.CONTACT_SUPPORT),
    element: (
      <LoggedOutRoute>
        <LoginWrapper>
          <Contact />
        </LoginWrapper>
      </LoggedOutRoute>
    ),
  },
  {
    path: childPath(HREF.LOGOUT),
    element: (
      <LoggedInRoute>
        <Logout />
      </LoggedInRoute>
    ),
  },
  {
    path: childPath(HREF.NOTFOUND),
    element: (
      <LoggedInRoute>
        <NotFoundPage />
      </LoggedInRoute>
    ),
  },
  {
    path: "*",
    element: <Navigate to={!isLoggedIn() ? HREF.LOGIN : HREF.NOTFOUND} replace />,
  },
];

export const router = createBrowserRouter(routes);
