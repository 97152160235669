import { FormImportantMessage, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { personalButtons, HREF } from "../../routes/routes";

const RequestError: React.FC = () => {
  const location = useLocation();

  const title = () => {
    if (location.pathname === HREF.HOME) return <FormattedMessage id="app.homepage" />;
    return personalButtons.find((button) => button.href === location.pathname)?.title;
  };

  if (location.pathname === HREF.PLANNER)
    return (
      <div className="container">
        <FormImportantMessage
          heading={<FormattedMessage id="error.planner-header" />}
          body={<FormattedMessage id="error.planner-body" />}
          className="mt-10 mb-4 mx-auto"
        />
      </div>
    );

  return <TextHeader text={title()} subText={<FormattedMessage id="error.request" />} />;
};

export default RequestError;
