import { DataOverview, DataOverviewPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { DataOverviewNonRetired } from "./DataOverviewNonRetired";
import { DataOverviewRetired } from "./DataOverviewRetired";
import { useQuery } from "@tanstack/react-query";
import { Pension } from "../../../lib/types";
import { getPension } from "../../../react-query/queries";
import { useHandleAuthError } from "../ErrorHandlingHelper";

const DataOverviewMain: React.FC = () => {
  const {
    data: pension,
    isLoading,
    error,
    refetch,
  } = useQuery<Pension>({
    queryKey: ["pension"],
    queryFn: getPension,
  });

  useHandleAuthError(error, refetch);

  const isRetired = !!pension?.isRetired;
  const retiredPension = pension?.retiredPension ?? null;
  const nonRetiredPension = pension?.nonRetiredPension ?? null;

  const hasRetiredPension = !!retiredPension && isRetired;
  const hasNonRetiredPension = !!nonRetiredPension && !isRetired;
  const isEmpty = pension === undefined || (retiredPension === null && nonRetiredPension === null);

  if (isLoading) {
    return (
      <DataOverviewPanel
        dataOverviews={
          <DataOverview
            ariaLabel=""
            badgeTitle="Loading"
            badges={[{ label: "", value: "" }]}
            subtitle={<FormattedMessage id="home.pension-your" />}
          />
        }
        className="h-full"
      />
    );
  }

  if (isEmpty) {
    return (
      <DataOverviewPanel
        dataOverviews={
          <DataOverview
            ariaLabel=""
            badgeTitle={<FormattedMessage id="home.no-pension-data" />}
            badges={[]}
            subtitle=""
          />
        }
      />
    );
  }

  return (
    <>
      {hasNonRetiredPension && (
        <DataOverviewNonRetired
          key={1}
          hasNonRetiredPension={hasNonRetiredPension}
          nonRetiredPension={nonRetiredPension}
        />
      )}
      {hasRetiredPension && (
        <DataOverviewRetired key={1} hasRetiredPension={hasRetiredPension} retiredPension={retiredPension} />
      )}
    </>
  );
};

export default DataOverviewMain;
