import { InformationPanel, Button } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { Person } from "../../../lib/types";
import { HREF } from "../../../routes/routes";

interface MyDataContactDetailsProps {
  user: Person;
}

const MyDataContactDetails: React.FC<MyDataContactDetailsProps> = ({ user }) => {
  const contactDetails = (user: Person) => {
    return [
      {
        label: <FormattedMessage id="utils.address" />,
        input: `${user.brpStreetName ?? ""} ${user.brpHouseNumber ?? ""}`,
      },
      {
        label: <FormattedMessage id="my-data.residence" />,
        input: `${user.brpPostalCode ?? ""} ${user.brpCityName ?? ""}`,
      },
      {
        label: <FormattedMessage id="utils.country" />,
        input: user.brpCountry,
      },
      {
        label: <FormattedMessage id="utils.phone" />,
        input: user.phoneNumber,
      },
    ];
  };

  return (
    <InformationPanel
      buttons={
        <Button
          size="md"
          text={<FormattedMessage id="utils.edit" />}
          href={HREF.CONTACT_DETAILS_FORM}
          target="_self"
        />
      }
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data.contact-details" />}
      informationDetails={contactDetails(user)}
    />
  );
};

export default MyDataContactDetails;
