const accessTokenName = "access_token";
const refreshTokenName = "refresh_token";
const expirationTime = "expiration_time";

const parseJWT = (token: string) => {
  const parts = token.split(".");
  if (parts.length !== 3) {
    console.error("Invalid JWT format");
    return null;
  }

  const base64Url = parts[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const getAuthToken = () => localStorage.getItem(accessTokenName);

const setAuthToken = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(accessTokenName, accessToken);
  localStorage.setItem(refreshTokenName, refreshToken);
  localStorage.setItem(expirationTime, parseJWT(accessToken)?.exp);
};

const getRefreshToken = () => localStorage.getItem(refreshTokenName);

const refreshAuthToken = async () => {
  try {
    if (!getRefreshToken()) {
      throw new Error("Invalid refresh token.");
    }
    const response = await fetch(window.env.VITE_AUTH_BASE_URL + "refresh", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refreshToken: getRefreshToken() }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const data: { accessToken: string; refreshToken: string } = await response.json();
    setAuthToken(data?.accessToken, data?.refreshToken);
    return data;
  } catch (error) {
    console.error(error);
    window.location.href = "/logout";
  }
};

const revokeAuthToken = async () => {
  try {
    const response = await fetch(window.env.VITE_AUTH_BASE_URL + "revoke", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getAuthToken() || "",
        "Content-Type": "application/json",
        "Api-Version": "OAuth",
      },
      body: JSON.stringify({ refreshToken: getRefreshToken() }),
    });

    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
  } catch (error) {
    console.error(error);
  }
};

const UNAUTHORIZED_ERROR_CODE = "401";

const isTokenExpired = () => {
  const tokenExpiry = localStorage.getItem(expirationTime);
  if (!tokenExpiry) {
    return true;
  }
  return Date.now() > parseInt(tokenExpiry) * 1000 - 60000;
};

export {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  revokeAuthToken,
  refreshAuthToken,
  isTokenExpired,
  UNAUTHORIZED_ERROR_CODE,
};
