import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: window.env.VITE_STALETIME,
      gcTime: window.env.VITE_GCTIME,
      refetchOnWindowFocus: false,
    },
  },
});
