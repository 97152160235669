import { FormTextBlock, FormField, Input, FormSubmit } from "@fonk-gitlab/bpfd-portal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { FieldValues, useForm } from "react-hook-form";
import { updateUserContactDetails } from "../../react-query/mutations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../organisms/Loading";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { Preferences } from "../../lib/types";
import { HREF } from "../../routes/routes";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../../utils/token";
import { TGivenNameSchema, useGivenNameSchema } from "../../hooks/schemas/generalSchema";

export const UserDetailsForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const data = useAuthentication();
  const user = data?.user;

  const {
    mutateAsync,
    isPending,
    error: postError,
    reset,
  } = useMutation({
    mutationFn: updateUserContactDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      navigate(HREF.MY_DATA);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateAsync(data);
          }
        });
      }
      setError("givenName", { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TGivenNameSchema>({
    mode: "onChange",
    resolver: zodResolver(useGivenNameSchema()),
    defaultValues: {
      givenName: user?.givenName,
    },
  });

  useEffect(() => {
    setValue("givenName", user?.givenName ?? "");
  }, [user, setValue]);

  const onSubmit = async (data: FieldValues) => {
    await mutateAsync({ givenName: data.givenName } as Preferences);
    reset();
  };

  if (data?.isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gradient-mint-blue-light">
      <div className="container flex-grow py-20">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <FormTextBlock heading={<FormattedMessage id="my-data.contact-details" />} />
              <FormField id="givenName" labelHeader={<FormattedMessage id="utils.givenName" />}>
                <Input
                  id="givenName"
                  name="givenName"
                  control={control}
                  disabled={isSubmitting || isPending}
                  error={!!errors.givenName}
                />
              </FormField>
              <FormField id="givenName">
                <FormSubmit
                  buttons={[
                    {
                      ariaLabel: "button",
                      text: <FormattedMessage id="utils.save-changes" />,
                      type: "submit",
                      disabled: !isDirty || isSubmitting || !isValid,
                    },
                    {
                      ariaLabel: "link",
                      onClick: () => {
                        window.history.back();
                      },
                      text: <FormattedMessage id="utils.cancel" />,
                    },
                  ]}
                  errorMessage={postError?.message}
                />
              </FormField>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
