import { useIntl } from "react-intl";
import { z } from "zod";
import {
  EmploymentOptionKeys,
  ExchangeType,
  LimitsAge,
  PlannerKeyParams,
  RetirementOptionsLimits,
} from "../../lib/types";
import { EarlierLaterFully } from "../../lib/constants";

export const usePlannerSchema = (plannerParam: PlannerKeyParams) => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });
  const EUROPEAN_NUMERIC_FORMAT = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;

  const requiredAgeSchema = (limits: LimitsAge) =>
    z.coerce
      .number({
        invalid_type_error: intlMessage("validation.age.format"),
      })
      .min(limits.currentAge.years, intlMessage("validation.age.range"))
      .max(limits.retirementAge.years, intlMessage("validation.age.retirement-range"))
      .optional();

  const requiredMonthSchema = z.coerce
    .number({
      invalid_type_error: intlMessage("validation.month.format"),
    })
    .min(0, intlMessage("validation.month.range"))
    .max(11, intlMessage("validation.month.range"))
    .optional();

  const requiredStartYearSchema = (retirementYear: number) =>
    z.coerce
      .number({
        invalid_type_error: intlMessage("validation.year.format"),
      })
      .min(new Date().getFullYear(), intlMessage("validation.age.range"))
      .max(retirementYear, intlMessage("validation.age.retirement-range"))
      .optional();

  const requiredSalarySchema = z.coerce
    .number({
      invalid_type_error: intlMessage("validation.salary.number"),
    })
    .min(1, intlMessage("validation.salary.positive"))
    .optional();

  // To be defined in details by user stories of planner
  const requiredHourSchema = z.coerce
    .number({ invalid_type_error: "Hours must be a number" })
    .min(1, intlMessage("validation.hours.range"))
    .max(60, intlMessage("validation.hours.range"))
    .optional();

  const getSurrenderOptionSchema = ({
    limits,
    isActive,
  }: {
    limits: RetirementOptionsLimits;
    isActive: boolean;
  }) =>
    z
      .object({
        isSurrenderSelected: z.boolean(),
        isRefrainFromSurrenderSelected: z.boolean(),
        ageYear: z.coerce.number().optional(),
        ageMonth: z.coerce
          .number()
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        calendarMonth: z
          .object({ value: z.coerce.number() })
          .transform((value) => value.value)
          .optional(),
        calendarYear: z.coerce
          .number()
          .max(limits?.latestDefaultRetirementCalendarYear ?? 0)
          .optional(),
      })
      .superRefine(({ ageYear, ageMonth = 0, isSurrenderSelected, calendarYear }, ctx) => {
        if (!isSurrenderSelected) return;

        if (!ageYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.year.required"),
            path: ["ageYear"],
          });
        }
        if (!ageYear) return;

        let minYears = limits?.latestDefaultRetirementAgeYears;
        let minMonths = limits?.earliestRetirementAgeMonths;
        const maxYears = limits?.latestDefaultRetirementAgeYears;
        const maxMonths = limits?.latestDefaultRetirementAgeMonths;
        let minCalendarYear = limits?.earliestRetirementCalendarYear;

        if (isActive) {
          minMonths = limits?.latestDefaultRetirementAgeMonths;
        }

        if (
          !isActive &&
          limits?.earliestSurrenderRetirementAgeMonths &&
          limits?.earliestSurrenderRetirementAgeYears &&
          limits?.earliestSurrenderRetirementCalendarYear
        ) {
          minYears = limits?.earliestSurrenderRetirementAgeYears;
          minMonths = limits?.earliestSurrenderRetirementAgeMonths;
          minCalendarYear = limits?.earliestSurrenderRetirementCalendarYear;
        }

        const isInvalidAgeYear = ageYear < minYears || ageYear > maxYears;
        const isInvalidAgeMonth =
          (ageYear === minYears && ageMonth < minMonths) || (ageYear === maxYears && ageMonth > maxMonths);
        const inValidCalendarYear = calendarYear && calendarYear < minCalendarYear;

        if (isInvalidAgeYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-years.invalid"),
            path: ["ageYear"],
          });
        }

        if (isInvalidAgeMonth) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-months.invalid"),
            path: ["ageMonth"],
          });
        }

        if (inValidCalendarYear) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-years.invalid"),
            path: ["calendarYear"],
          });
        }
      });

  const getEarlierOrLatestOptionSchema = ({ limits }: { limits: RetirementOptionsLimits }) =>
    z
      .object({
        isEarlierOrLaterRetirementSelected: z.boolean(),
        fullyOrPartial: z.object({ id: z.string(), label: z.string(), value: z.number() }),
        partialAgeYear: z.coerce.number().optional(),
        partialAgeMonth: z.coerce
          .number()
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        partialCalendarMonth: z.object({ value: z.coerce.number() }).optional(),
        partialCalendarYear: z.coerce.number().optional(),
        fullAgeYear: z.coerce.number().optional(),
        fullAgeMonth: z.coerce
          .number()
          .min(0, intlMessage("validation.month.range"))
          .max(11, intlMessage("validation.month.range"))
          .optional(),
        fullCalendarMonth: z.object({ value: z.coerce.number() }).optional(),
        fullCalendarYear: z.coerce.number().max(limits.latestRetirementCalendarYear).optional(),
      })
      .refine(
        ({ isEarlierOrLaterRetirementSelected, fullAgeYear }) => {
          const isAgeYearRequired = isEarlierOrLaterRetirementSelected && !fullAgeYear;
          return !isAgeYearRequired;
        },
        { message: intlMessage("validation.year.required"), path: ["fullAgeYear"] }
      )
      .superRefine(
        ({ fullAgeYear, fullAgeMonth = 0, isEarlierOrLaterRetirementSelected, fullCalendarYear }, ctx) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (!fullAgeYear) return;

          const maxYears = limits?.latestRetirementAgeYears;
          const minYears = limits?.earliestRetirementAgeYears;
          const maxMonths = limits?.latestRetirementAgeMonths;
          const minMonths = limits?.earliestRetirementAgeMonths;
          const minCalendarYear = limits.earliestRetirementCalendarYear;

          const isInvalidAgeYears = fullAgeYear < minYears || fullAgeYear > maxYears;
          const isInValidAgeMonths =
            (fullAgeYear === minYears && fullAgeMonth < minMonths) ||
            (fullAgeYear === maxYears && fullAgeMonth > maxMonths);
          const isInValidCalendarYear = fullCalendarYear && fullCalendarYear < minCalendarYear;

          if (isInvalidAgeYears) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["fullAgeYear"],
            });
          }

          if (isInValidAgeMonths) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-months.invalid"),
              path: ["fullAgeMonth"],
            });
          }

          if (isInValidCalendarYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.calendar-year.invalid"),
              path: ["fullCalendarYear"],
            });
          }
        }
      )
      .refine(
        ({ isEarlierOrLaterRetirementSelected, partialAgeYear, fullyOrPartial }) => {
          const isAgeYearRequired =
            isEarlierOrLaterRetirementSelected &&
            fullyOrPartial?.value !== EarlierLaterFully &&
            !partialAgeYear;
          return !isAgeYearRequired;
        },
        { message: intlMessage("validation.year.required"), path: ["partialAgeYear"] }
      )
      .superRefine(
        (
          { partialAgeYear, partialAgeMonth = 0, isEarlierOrLaterRetirementSelected, partialCalendarYear },
          ctx
        ) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (!partialAgeYear) return;

          const maxYears = limits?.latestRetirementAgeYears;
          const minYears = limits?.earliestRetirementAgeYears;
          const maxMonths = limits?.latestRetirementAgeMonths - 1;
          const minMonths = limits?.earliestRetirementAgeMonths;
          const minCalendarYear = limits.earliestRetirementCalendarYear;

          const isValidAgeYears = partialAgeYear >= minYears && partialAgeYear <= maxYears;
          const isInValidMonths =
            (partialAgeYear === minYears && partialAgeMonth < minMonths) ||
            (partialAgeYear === maxYears && partialAgeMonth > maxMonths);
          const isInValidCalendarYear = partialCalendarYear && partialCalendarYear < minCalendarYear;

          if (!isValidAgeYears) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["partialAgeYear"],
            });
          }

          if (isInValidMonths) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-months.invalid"),
              path: ["partialAgeMonth"],
            });
          }

          if (isInValidCalendarYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.calendar-year.invalid"),
              path: ["partialCalendarYear"],
            });
          }
        }
      )
      .superRefine(
        (
          {
            fullAgeYear,
            fullAgeMonth = 0,
            isEarlierOrLaterRetirementSelected,
            fullyOrPartial,
            partialAgeMonth = 0,
            partialAgeYear,
          },
          ctx
        ) => {
          if (!isEarlierOrLaterRetirementSelected) return;
          if (fullyOrPartial.value === EarlierLaterFully) return;
          if (!fullAgeYear || !partialAgeYear) return;

          if (partialAgeYear > fullAgeYear) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["partialAgeYear"],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: intlMessage("validation.age-years.invalid"),
              path: ["fullAgeYear"],
            });
          } else if (partialAgeYear === fullAgeYear) {
            if (partialAgeMonth >= fullAgeMonth) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.age-months.invalid"),
                path: ["partialAgeMonth"],
              });
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.age-months.invalid"),
                path: ["fullAgeMonth"],
              });
            }
          }
        }
      );

  const getExchangeOptionSchema = (limits: RetirementOptionsLimits) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        exchangeType: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform((options) => options.find((option) => option.checked)?.value)
          .optional(),
        isMaxExchange: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform((options) => options.find((option) => option.checked)?.value === "yes")
          .optional(),
        exchangeAmount: z
          .string()
          .regex(EUROPEAN_NUMERIC_FORMAT)
          .transform((value, ctx) => {
            const parsedNumber = parseFloat(value.replace(/\./g, "").replace(",", "."));
            if (isNaN(parsedNumber)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.invalid"),
              });
              return z.NEVER;
            }
            if (!parsedNumber && parsedNumber <= 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.invalid"),
              });
              return z.NEVER;
            }
            return parsedNumber ?? 0;
          })
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.exchangeType === undefined), {
        message: intlMessage("Required"),
        path: ["exchangeType"],
      })
      .refine(
        (data) =>
          !(
            data.exchangeType === ExchangeType.OPPP &&
            data?.exchangeAmount &&
            data.exchangeAmount > limits?.defaultMaximumExchangeOP
          ),
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["exchangeAmount"],
        }
      )
      .refine(
        (data) =>
          !(
            data.exchangeType === ExchangeType.PPOP &&
            data?.exchangeAmount &&
            data.exchangeAmount > limits?.defaultMaximumExchangePP
          ),
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["exchangeAmount"],
        }
      );
  };

  const getBridgingOptionSchema = () => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        isMaxExchangeAow: z
          .array(z.object({ value: z.string(), checked: z.boolean() }))
          .transform((options) => options.find((option) => option.checked)?.value === "yes")
          .optional(),
        maxExchangeAowAmount: z.number().optional(),
        exchangeAowAmount: z
          .string()
          .regex(EUROPEAN_NUMERIC_FORMAT)
          .transform((value, ctx) => {
            const parsedNumber = parseFloat(value.replace(/\./g, "").replace(",", "."));
            if (isNaN(parsedNumber)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.invalid"),
              });
              return z.NEVER;
            }
            if (!parsedNumber && parsedNumber <= 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intlMessage("validation.amount.invalid"),
              });
              return z.NEVER;
            }
            return parsedNumber ?? 0;
          })
          .optional(),
      })
      .refine(
        (data) =>
          !(
            data?.exchangeAowAmount &&
            data?.maxExchangeAowAmount &&
            data?.exchangeAowAmount > data?.maxExchangeAowAmount
          ),
        {
          message: intlMessage("validation.amount.invalid"),
          path: ["exchangeAowAmount"],
        }
      );
  };

  const getHLLHSchema = () =>
    z
      .object({
        isSelected: z.boolean(),
        exchangeOptions: z
          .object({ checked: z.boolean(), value: z.string() })
          .array()
          .refine(
            (values) => {
              const isAnyExchangeOptionsChecked = !!values.find((item) => item.checked);
              return isAnyExchangeOptionsChecked;
            },
            {
              message: "Please select an option",
            }
          )
          .optional(),
        numberOfMonths: z.coerce
          .number()
          .min(1, "Months must be between 1 and 144")
          .max(144, "Months must be between 1 and 144")
          .optional(),
        calendarMonth: z
          .object({ value: z.coerce.number() })
          .transform((value) => value.value)
          .optional(),
        calendarYear: z.coerce.number().min(1900, "Invalid value").optional(),
      })
      .refine(
        ({ isSelected, numberOfMonths }) => {
          const isValid = !isSelected || (isSelected && numberOfMonths);
          return isValid;
        },
        {
          message: "Required",
          path: ["numberOfMonths"],
        }
      );

  const getWorkingTimeOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        hours: requiredHourSchema,
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number() })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.hours === undefined), {
        message: intlMessage("validation.required"),
        path: ["hours"],
      })
      .refine((data) => !(data.hours && data.ageYear === undefined), {
        message: intlMessage("validation.age-year.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          const currentAge = limitAge?.currentAge;
          const retirementAge = limitAge?.retirementAge;
          if (
            (data?.ageYear === currentAge?.years && ageMonth < currentAge?.months) ||
            (data?.ageYear === retirementAge?.years && ageMonth > retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getEarningSalaryOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        salary: requiredSalarySchema,
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number() })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.salary === undefined), {
        message: intlMessage("validation.required"),
        path: ["salary"],
      })
      .refine((data) => !(data.salary && data.ageYear === undefined), {
        message: intlMessage("validation.salary.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          if (
            (data?.ageYear === limitAge?.currentAge?.years && ageMonth < limitAge?.currentAge?.months) ||
            (data?.ageYear === limitAge?.retirementAge?.years && ageMonth > limitAge?.retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getQuittingWorkOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        isSelected: z.boolean().optional(),
        ageYear: requiredAgeSchema(limitAge),
        ageMonth: requiredMonthSchema,
        calendarYear: requiredStartYearSchema(retirementYear),
        calendarMonth: z
          .object({ value: z.coerce.number() })
          .transform((value) => value.value - 1)
          .optional(),
      })
      .refine((data) => !(data.isSelected && data.ageYear === undefined), {
        message: intlMessage("validation.required"),
        path: ["ageYear"],
      })
      .refine(
        (data) => {
          const ageMonth = data.ageMonth ?? 0;
          if (
            (data?.ageYear === limitAge?.currentAge?.years && ageMonth < limitAge?.currentAge?.months) ||
            (data?.ageYear === limitAge?.retirementAge?.years && ageMonth > limitAge?.retirementAge?.months)
          ) {
            return false;
          }
          return true;
        },
        {
          message: intlMessage("validation.age-months.exceed"),
          path: ["ageMonth"],
        }
      );
  };

  const getEmploymentOptionSchema = (limitAge: LimitsAge, retirementYear: number) => {
    return z
      .object({
        workingTimeOption: getWorkingTimeOptionSchema(limitAge, retirementYear).optional(),
        earningSalaryOption: getEarningSalaryOptionSchema(limitAge, retirementYear).optional(),
        quittingWorkOption: getQuittingWorkOptionSchema(limitAge, retirementYear).optional(),
      })
      .optional();
  };

  const getRetirementOptionsSchema = ({
    limits,
    isActive,
  }: {
    limits: RetirementOptionsLimits;
    isActive: boolean;
  }) => {
    return z
      .object({
        surrenderOptions: getSurrenderOptionSchema({ limits, isActive }).optional(),
        earlierOrLaterRetirementOptions: getEarlierOrLatestOptionSchema({ limits }).optional(),
        exchangeOption: getExchangeOptionSchema(limits).optional(),
        bridgingOption: getBridgingOptionSchema().optional(),
        highLowLowHigh: getHLLHSchema().optional(),
      })
      .optional();
  };

  return z
    .object({
      employmentOptions: getEmploymentOptionSchema(plannerParam?.limitAge, plannerParam?.retirementYear),
      retirementOption: getRetirementOptionsSchema({
        limits: plannerParam?.retirementOptionsLimits,
        isActive: plannerParam.isActive,
      }),
    })
    .superRefine(({ employmentOptions, retirementOption }, ctx) => {
      const { workingTimeOption, earningSalaryOption, quittingWorkOption } = employmentOptions ?? {};

      let optionSelected: EmploymentOptionKeys | undefined;
      if (workingTimeOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.WorkingTimeOption;
      } else if (earningSalaryOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.EarningSalaryOption;
      } else if (quittingWorkOption?.isSelected) {
        optionSelected = EmploymentOptionKeys.QuittingWorkOption;
      }

      if (!optionSelected) return;

      const ageYear = employmentOptions?.[optionSelected]?.ageYear;
      const ageMonth = employmentOptions?.[optionSelected]?.ageMonth ?? 0;
      const { fullAgeYear, fullAgeMonth = 0 } = retirementOption?.earlierOrLaterRetirementOptions ?? {};

      if (!ageYear || !fullAgeYear) return;

      if (ageYear > fullAgeYear) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.age-years.invalid"),
          path: ["employmentOptions", optionSelected, "ageYear"],
        });
      } else if (ageYear === fullAgeYear) {
        if (ageMonth > fullAgeMonth - 1) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.age-months.invalid"),
            path: ["employmentOptions", optionSelected, "ageMonth"],
          });
        }
      }
    });
};

export type TPlannerSchema = z.infer<ReturnType<typeof usePlannerSchema>>;
