import { FormImportantMessage, IntroPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage, useIntl } from "react-intl";
import { Message, Person } from "../../lib/types";
import { useQuery } from "@tanstack/react-query";
import { getHomepageWarnings } from "../../react-query/queries";
import { useHandleAuthError } from "./ErrorHandlingHelper";
import { PortalLanguage } from "../../utils/languageLoader";

const FormattedWarnings = (warnings: Message[], locale: string) => {
  return (
    <ul>
      {warnings?.map((warning: Message) => {
        const value = locale === PortalLanguage.NL ? warning.textNL : warning.textUS;
        return <li key={value}>{value}</li>;
      })}
    </ul>
  );
};

interface IntroPanelHomeProps {
  user: Person;
  isUnderConstruction: boolean;
}

const IntroPanelHome: React.FC<IntroPanelHomeProps> = ({ user, isUnderConstruction }) => {
  const { locale } = useIntl();
  const {
    data: warnings,
    error,
    refetch,
  } = useQuery({
    queryKey: ["warnings"],
    queryFn: getHomepageWarnings,
    select: (data) => data.messages as Message[],
  });

  useHandleAuthError(error, refetch);

  const name = `${user?.firstName.slice(0, 1)}. ${user?.lastName}`;

  const hasWarnings = () => warnings && warnings.length > 0;
  const headingText = !hasWarnings() ? " " : <FormattedMessage id="home.intro-panel-warning" />;
  const bodyText = hasWarnings() ? "" : <FormattedMessage id="home.intro-panel-body" />;

  return (
    <IntroPanel
      introductionText={<FormattedMessage id="utils.welcome" />}
      name={name}
      heading={headingText}
      body={bodyText}
      buttonText={isUnderConstruction ? "" : <FormattedMessage id="home.compare-scenarios" />}
      warnings={
        hasWarnings() ? (
          <FormImportantMessage body={FormattedWarnings(warnings || [], locale)} className="my-2" />
        ) : null
      }
    />
  );
};

export default IntroPanelHome;
