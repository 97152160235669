import { ActionPanel, Button, PageLayout } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { HREF } from "../../routes/routes";
import IntroPanelHome from "../organisms/IntroPanelHome";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { useAuthentication } from "../../context/AuthenticatedContext";
import DataOverviewMain from "../organisms/dataOverview/DataOverviewMain";
import { Fragment } from "react/jsx-runtime";

const isUnderConstruction = true;

const Homepage = () => {
  const data = useAuthentication();
  if (data?.isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      <Fragment key=".0">
        <div className="container flex-grow py-20">
          {data?.user && (
            <div className="grid grid-cols-12 gap-x-1 md:gap-x-2">
              <div className="col-span-12 col-start-1 lg:col-start-2 lg:col-span-6 ">
                <div className="mb-2">
                  <IntroPanelHome user={data?.user} isUnderConstruction={isUnderConstruction} />
                </div>
                <div>
                  <ActionPanel
                    buttons={
                      !isUnderConstruction && (
                        <Button
                          size="sm"
                          text={<FormattedMessage id="home.submit-change" />}
                          href={HREF.ACTIONS}
                          disabled
                        />
                      )
                    }
                    className="mb-2"
                    header={
                      isUnderConstruction ? (
                        <FormattedMessage id="utils.construction" />
                      ) : (
                        <FormattedMessage id="home.make-a-change" />
                      )
                    }
                    image={{
                      alt: "",
                      height: 200,
                      src: "https://pensioenfondsdetailhandel.nl/assets/img/Illustration__8_2x.png",
                      width: 200,
                    }}
                  />
                </div>
              </div>
              <div className="col-start-1 col-span-12  w-full md:col-span-12 lg:col-span-4">
                <DataOverviewMain />
              </div>
            </div>
          )}
        </div>
      </Fragment>
    </PageLayout>
  );
};

export default Homepage;
