import { Header, PageLayout, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { Link, useNavigate } from "react-router-dom";
import { HREF } from "../../routes/routes";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="bg-gradient-mint-blue-light flex flex-col min-h-screen">
        <Header
          clickLogo={() => navigate(HREF.HOME)}
          headerButtonHandler={() => {
            // do nothing.
          }}
          headerButtonsData={[]}
          links={[]}
          onNavClick={() => {
            // do nothing.
          }}
        />
        <TextHeader text={"Pagina niet gevonden"} subText={<Link to={HREF.HOME}>Home pagina</Link>} />
      </div>
    </PageLayout>
  );
};

export default NotFoundPage;
